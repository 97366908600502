<template>
  <div>
    <!-- <Navbar />
    <Sidebar /> -->

    <section class="main no-flex">
      <InvProductTable
        :material-id="materialIdToShow"
      />
    </section>
  </div>
</template>

<script>
// @ is an alias to /src
import Sidebar from '@/components/sidebars/Sidebar.vue'
import Navbar from '@/components/Navbar.vue'
import InvProductTable from '@/components/products/InvProductTable.vue'


export default {
  name: 'Home',
  components: {
    // eslint-disable-next-line vue/no-unused-components
    Sidebar,
    // eslint-disable-next-line vue/no-unused-components
    Navbar,
    InvProductTable,
  },
  data() {
    return {
      materialIdToShow: '',
    }
  },
  methods: {
    materialId(id) {
      this.materialIdToShow = id
    },
  },
}
</script>
<style scoped lang="scss">
.home {
  padding: 30px 15px 15px 120px;
}

@media screen and (min-width: 1500px) and (max-width: 1650px) {
  .home{
    padding: 60px 15px 15px 90px !important;
  }
}

@media screen and (min-width: 1340px) and (max-width: 1500px) {
  .home{
    padding: 60px 15px 15px 90px !important;
  }
}
@media screen and (max-width: 1340px){
  .home{
    padding: 60px 15px 15px 90px;
  }
}
@media screen and (max-width: 1190px) {
  .main {
    padding: 90px 0.75rem 0 0.75rem;
  }

  .home {
    padding: 30px 15px 15px 20px;
  }
}
</style>
