<template>
  <div @click="removeLists">

    <b-tabs
      v-model="tabIndex"
    >
      <b-tab
        title="All Lists"
      >
        <template #title>
          <strong style="font-weight: 500; color: black">{{ $t("ProductReporting") }}</strong>
        </template>
        <div>
          <div
            class="table__header"
            style="justify-content: flex-end"
          >
            <div
              style="display: flex"
              class="filtering"
            >
              <div
                class="search-toggle"
                style="padding-right: 15px"
              >
                <p>{{ $t("SearchBy") }}</p>
                <div>
                  <p class="active2">
                    {{ $t("MainCategoryName") }}
                  </p>
                </div>
              </div>
              <div>
                <div>
                  <div class="black">
                    <b-icon-search
                      v-if="searchByName == ''"
                      class="search1"
                      style="position: absolute; left: 5px; top: 12px"
                    />
                    <b-icon-x-circle
                      v-else-if="searchByName != '' && !noSearch"
                      class="search1"
                      style="position: absolute; right: 20px; top: 12px; cursor: pointer"
                      @click="searchByName = ''"
                    />
                    <input
                      v-model="searchByName"
                      type="text"
                      autocomplete="off"
                      name="search-box"
                      :placeholder="$t('MaterialName')"
                      :style="
                        getFilteredDresses.length > 0 && noSearch
                          ? 'border-radius: 8px 8px 0px 0px'
                          : ''
                      "
                      @keyup="inputChanged"
                      @keydown.down="onArrowDown"
                      @keydown.up="onArrowUp"
                    >
                  </div>
                  <div
                    v-if="getFilteredDresses.length > 0 && noSearch"
                    ref="scrollContainer"
                    class="dropdrop"
                    :style="getFilteredDresses.length > 7 ? 'height: 210px' : 'height: auto'"
                  >
                    <div
                      v-for="(result, index) in getFilteredDresses"
                      :key="index"
                      ref="options"
                      style="cursor: pointer"
                      :class="{ 'is-active': index === arrowCounter }"
                      class="search-item"
                      @click="
                        searchByOn(result);
                        noSearch = false;
                      "
                    >
                      <p style="margin: 0">
                        {{ result }}
                      </p>
                    </div>
                    <!-- <div
                  v-if="getFilteredDresses.length == 0"
                  class="search-item"
                >
                  <p>Sorry. No Results.</p>
                </div> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            style="width: 100%;"
          >
            <div style="height: 78vh; overflow-y: scroll">
              <ul class="ul-order">
                <li
                  v-for="item in getProductsReport"
                  :key="item.id"
                >
                  <div class="order-card">
                    <p style="width: 15%; text-align: left">
                      <span class="order-card__title">{{ $t("MainCategoryName") }}</span>
                      <span class="createdInput">{{ item.name }}</span>
                    </p>
                    <p style="width: 15%; text-align: left">
                      <span class="order-card__title">{{ $t("Created") }}: </span>
                      <span class="createdInput">{{ convertUTCDateToLoacalDate(item.created) }}</span>
                    </p>
                    <b-button
                      variant="outline-primary"
                      :class="{
                        active: item.id === buttonActive,
                      }"
                      style="height: 37px; width: 197px; border-radius: 6px; margin-top: 7px"
                      @click="loadColors(item.id)"
                    >
                      {{ $t("ShowColors") }}
                    </b-button>
                  </div>
                  <ul v-if="item.id === actualId">
                    <li>
                      <span style="font-weight: 600">{{ $t("Color") }}:</span>
                      <div class="flex">
                        <p
                          v-for="color in getDressColors"
                          :key="color.mainDressCategoryColorId"
                          class="color-card"
                          :class="{
                            active: color.mainDressCategoryColorId === colorActive,
                          }"
                          @click="loadSizes(color.mainDressCategoryColorId)"
                        >
                          <b-button
                            v-b-tooltip.hover
                            style="
                              background: transparent
                                linear-gradient(52deg, #262e6c 0%, #9b2a5c 100%, #ff274f 100%) 0% 0%
                                no-repeat padding-box;
                              color: #181614;
                              border-color: transparent;
                            "
                            :title-item-class="'tab-title-class'"
                          >
                            <!-- :title="$('SeeArticleDetails')" -->
                            {{ color.colorName }}
                          </b-button>
                        </p>
                      </div>
                    </li>
                    <li>
                      <span style="font-weight: 600">{{ $t("ArticleDetails") }}:</span>
                      <div class="flex">
                        <p
                          v-for="size in getDressesSize"
                          :key="size.id"
                          class="color-card"
                          :class="{
                            active: size.articleNumber === sizeActive,
                          }"
                          @click="loadMaterialByArticleNum(size.articleNumber)"
                        >
                          <b-button
                            v-b-tooltip.hover
                            style="
                              background: transparent
                                linear-gradient(52deg, #262e6c 0%, #9b2a5c 100%, #ff274f 100%) 0% 0%
                                no-repeat padding-box;
                              color: #f4f1ed;
                              border-color: transparent;
                              min-width: 300px;
                            "
                            :title="$t('SeeMaterial')"
                            :title-item-class="'tab-title-class'"
                          >
                            <!-- Hover Me -->
                            <div style="display: flex; justify-content: space-between">
                              <p style="color: rgb(38, 46, 108);font-weight: bold;">
                                {{ $t("ArticleName") }}:
                              </p>
                              <p style="color:black;">
                                {{ size.articleNumber }}
                              </p>
                            </div>
                            <div class="hr" />
                            <div style="display: flex; justify-content: space-between">
                              <p style="color: rgb(38, 46, 108);font-weight: bold;">
                                {{ $t("Size") }}:
                              </p>
                              <p style="color:black;">
                                {{ size.size }}
                              </p>
                            </div>
                            <!-- {{ color.colorName }} -->
                          </b-button>
                        </p>
                      </div>
                    </li>
                    <li>
                      <span style="font-weight: 600">{{ $t("MaterialVariantName") }}:</span>
                      <div class="order-card__wrapper">
                        <div
                          v-for="product in getMaterialsByArticleNum.productSingleMaterialVariantDtos"
                          :key="product.created"
                          class="order-card__inside"
                        >
                          <!-- <b-button
                        v-b-tooltip.hover
                        style="background-color: #242f6e"
                      > -->
                          <div
                            style="display: flex; justify-content: space-between"
                            class=""
                          >
                            <!-- Hover Me -->
                            <p style="color: rgb(38, 46, 108); font-weight: bold">
                              {{ $t("Name") }}:
                            </p>
                            <p>{{ product.name }}</p>
                            <!-- {{ color.colorName }} -->
                          </div>
                          <div class="hr" />
                          <div
                            style="margin-top: 10px; display: flex; justify-content: space-between"
                          >
                            <p
                              v-if="product.length > 0"
                              style="color: rgb(38, 46, 108); font-weight: bold"
                            >
                              {{ $t("Length") }}:
                            </p>
                            <p
                              v-else
                              style="color: rgb(38, 46, 108); font-weight: bold"
                            >
                              {{ $t("Quantity") }}:
                            </p>
                            {{ product.length == 0 ? product.quantity : product.length }}
                          </div>
                          <div class="hr" />
                          <div
                            style="margin-top: 10px; display: flex; justify-content: space-between"
                          >
                            <p style="color: rgb(38, 46, 108); font-weight: bold">
                              {{ $t("MeasurementType") }}:
                            </p>
                            {{ product.measurementType }}
                          </div>
                          <!-- </b-button> -->
                        </div>
                      </div>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
          <div>
            <div>
              <b-pagination
                v-if="getTotalItemsForDresses > 15"
                v-model="page"
                :total-rows="getTotalItemsForDresses"
                :per-page="pageSize"
                first-number
                last-number
                align="fill"
                prev-class="prev-itemi"
                next-class="next-itemi"
                class="mt-1 mb-0"
              >
                <template #prev-text>
                  <span>{{ $t("Previous") }}</span>
                </template>
                <template #next-text>
                  <span>{{ $t("Next") }}</span>
                </template>
              </b-pagination>
            </div>
          </div>
        </div>
      </b-tab>
      <b-tab
        title="Tab 2"
        :active="colorProp == 'true' || colorProp"
        @click="fetchColors"
      >
        <template #title>
          <strong style="font-weight: 500; color: black">{{ $t("ArticlesByColor") }}</strong>
        </template>
        <div
          class="mats-listing__wrapper"
          style="margin-top: 15px; width: 100% !important"
          @click="removeLists2"
        >
          <div
            class="table__header"
            style="justify-content: flex-end; margin-bottom: 10px;"
          >
            <div
              style="display: flex"
              class="filtering"
            >
              <div
                class="search-toggle"
                style="padding-right: 15px"
              >
                <p>{{ $t("SearchBy") }}</p>
                <div>
                  <p class="active2">
                    {{ $t("Color") }}
                  </p>
                </div>
              </div>
              <div>
                <div>
                  <div
                    class="black"
                  >
                    <b-icon-search
                      v-if="searchColor == null"
                      style="position: absolute; left: 5px; top: 12px"
                    />
                    <b-icon-x-circle
                      v-else-if="searchColor != null && !noSearch2"
                      class="search1"
                      style="position: absolute; right: 20px; top: 12px; cursor: pointer"
                      @click="searchColor = null"
                    />
                    <input
                      v-model="searchColor"
                      type="text"
                      autocomplete="off"
                      name="search-box"
                      :placeholder="$t('SearchByColor')"
                      :style="getFilteredDressColors.length > 0 && noSearch2 ? 'border-radius: 8px 8px 0px 0px' : ''"
                      @keyup="inputChanged2"
                      @keydown.down="onArrowDown2"
                      @keydown.up="onArrowUp2"
                    >
                  </div>
                  <div
                    v-if="getFilteredDressColors.length > 0 && noSearch2"
                    ref="scrollContainer2"
                    class="dropdrop"
                    :style="getFilteredDressColors.length > 7 ? 'height: 210px' : 'height: auto'"
                  >
                    <div
                      v-for="(result, index) in getFilteredDressColors"
                      :key="index"
                      ref="options"
                      style="cursor: pointer"
                      class="search-item"
                      :class="{ 'is-active': index === arrowCounter2 }"
                      @click="searchByOn2(result); noSearch2 = false;"
                    >
                      <p style="margin:2 0">
                        {{ result }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style="width: 100%;  padding-top: 16px; height: 74vh; overflow-y: scroll;">
            <table
              class="team_table"
            >
              <thead style="border-bottom: 1px solid #dcdfe6; color: #606266; text-align: left">
                <tr>
                  <th style="padding: 10px; border-radius: 0px 0px 0px 0px; width: 22%">
                    {{ $t("ArticleN") }}
                  </th>
                  <th style="padding: 10px; border-radius: 0px 0px 0px 0px; width: 20%">
                    {{ $t("Size") }}
                  </th>
                  <th style="padding: 10px; border-radius: 0px 0px 0px 0px; width: 22%">
                    {{ $t("Price") }}
                  </th>
                  <th style="padding: 10px; border-radius: 0px 0px 0px 0px; width: 22%; text-align: center;">
                    {{ $t("ViewInStock") }}
                  </th>
                </tr>
              </thead>
              <tbody v-if="getReportingInventoryProductSizesByMDCColorName.length > 0">
                <tr
                  v-for="order in getReportingInventoryProductSizesByMDCColorName"
                  :key="order.id"
                  style="border-bottom: 1px solid #dcdfe6; color: #606266; cursor: pointer;"
                  :style="classNameCh === order.articleNumber ? 'background-color: rgba(255, 39, 79, 0.1)' : ''"
                >
                  <td style="padding: 9px">
                    {{ order.articleNumber }}
                  </td>
                  <td style="padding: 9px">
                    {{ $t(order.size) }}
                  </td>
                  <td style="padding: 9px;">
                    {{ order.price ? `${numbersWithcomma(order.price)}€` : '0.00€' }}
                  </td>
                  <td
                    style="padding: 9px; text-align: center;"
                    @click="matProps(order)"
                  >
                    <!-- <button @click="toggleRightTable(userr)">
                      Show Right Table
                    </button> -->
                    <button class="editButton">
                      {{ $t('View') }}
                    </button>
                  </td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr>
                  <td
                    colspan="6"
                    style="padding: 9px; text-align: left"
                  >
                    {{ $t("NoOrderWasFound") }}!
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div>
            <b-pagination
              v-if="getTotalItemsForMaterials > 15"
              v-model="page"
              :total-rows="getTotalItemsForMaterials"
              :per-page="pageSize"
              first-number
              last-number
              align="fill"
              size="md"
              prev-class="prev-itemi"
              next-class="next-itemi"
              class="mt-1 mb-0"
            >
              <template #prev-text>
                <span>{{ $t("Previous") }}</span>
              </template>
              <template #next-text>
                <span>{{ $t("Next") }}</span>
              </template>
            </b-pagination>
          </div>
        </div>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';

export default {
  components: {},
  props: {
    materialId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      noSearch: false,
      noSearch2: false,
      classNameCh: '',
      searchByName: '',
      sizeActive: '',
      colorActive: '',
      tabIndex: 0,
      searchColor: null,
      buttonActive: '',
      toggleSarch: false,
      actualId: '',
      totalItems: '',
      page: 1,
      pageSize: 15,
      colorProp: null,
      arrowCounter: 0,
      arrowCounter2: 0,
      enterPressed: false,
    };
  },
  computed: {
    ...mapGetters([
      'getDressColors',
      'getDressesSize',
      'getProductsReport',
      'getMaterialsByArticleNum',
      'getTotalItemsForDresses',
      'getFilteredDresses',
      'getIsLoading',
      'getReportingInventoryProductSizesByMDCColorName',
      'getCurrentPageForMaterials',
      'getTotalItemsForMaterials',
      'getFilteredDressColors',
    ]),
  },
  watch: {
    // // eslint-disable-next-line func-names
    page(value) {
      if (this.tabIndex == 0) {
        this.loadProductReportingPagination({
          pageNumber: value,
          pageSize: this.pageSize,
          mdcName: this.searchByName,
        });
      } else if (this.tabIndex == 1) {
        this.reporting_inventoryProductSizesByMDCColorName({
          name: null,
          pageNumber: value,
          pageSize: this.pageSize,
        })
      }
    },
    searchByName(value) {
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (value == '') {
          this.removeLists();
          this.loadProductReportingPagination({
            pageNumber: this.page,
            pageSize: this.pageSize,
            mdcName: this.searchByName,
          });
        } else if (this.enterPressed) {
        // eslint-disable-next-line no-useless-return
          return;
        } else {
          this.filteredDressesByName(value);
          this.noSearch = true;
        }
      }, 500);
    },
    searchColor(value) {
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (value == null) {
          this.removeLists()
          this.reporting_inventoryProductSizesByMDCColorName({
            name: null,
            pageNumber: this.page,
            pageSize: this.pageSize,
          })
        } else if (this.enterPressed) {
        // eslint-disable-next-line no-useless-return
          return;
        } else {
          this.filteredDressColorsByName({ mainDressCategoryId: null, name: this.searchColor });
          this.noSearch2 = true
        }
      }, 500);
    },
  },
  mounted() {
    this.colorProp = this.$route.query.color
    // this.searchColor = this.$route.query.articleNumber
    // const articleNumberParts = this.$route.query.articleNumber.split('-');
    // const middleWordIndex = Math.floor(articleNumberParts.length / 2);
    // this.searchColor = articleNumberParts[middleWordIndex];
    // this.reporting_inventoryProductSizesByMDCColorName({
    //   name: this.searchColor,
    //   pageNumber: this.page,
    //   pageSize: this.pageSize,
    // });
    // console.log(this.$route.query)
    // this.noSearch2 = false
    // this.tabIndex = 1
    // eslint-disable-next-line no-unused-expressions
    this.colorProp === 'true' ? this.tabIndex = 1 : this.tabIndex = 0; this.fetch();
    // if (this.colorProp === 'true' || this.colorProp) {
    //   this.tabIndex = 1
    //   this.tabIndex++
    // } else {
    // }
  },
  methods: {
    ...mapActions([
      'loadDressColorsById',
      'inventoryProductSizeFromColorId',
      'productsByArticleNumber',
      'resetSizes',
      'resetProductsByArticleNumber',
      'loadProductReportingPagination',
      'filteredDressesByName',
      'changeLoadingtoTrue',
      'resetFilteredDresses',
      'reporting_inventoryProductSizesByMDCColorName',
      'filteredDressColorsByName',
      'resetFilteredDressColors',

    ]),
    numbersWithcomma(value) {
      const options = {
        style: 'decimal', // Other options: 'currency', 'percent', etc.
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      };
      return value.toLocaleString('en-US', options);
    },
    fetchColors() {
      this.searchColor = null
      this.noSearch2 = false
      this.reporting_inventoryProductSizesByMDCColorName({
        name: null,
        pageNumber: this.page,
        pageSize: this.pageSize,
      })
    },
    async fetch() {
      try {
        // this.changeLoadingtoTrue(false)

        await this.loadProductReportingPagination({
          pageNumber: this.page,
          pageSize: this.pageSize,
          mdcName: this.searchByName,
        }).then((res) => {
          console.log(res);
          // this.changeLoadingtoTrue(true);
        });
      } catch (e) {
        console.log(e);
        // this.changeLoadingtoTrue(false)
      }
    },
    onArrowDown(ev) {
      ev.preventDefault();
      if (this.arrowCounter < this.getFilteredDresses.length - 1) {
        this.arrowCounter += 1;
        this.fixScrolling();
      }
    },

    onArrowUp(ev) {
      ev.preventDefault();
      if (this.arrowCounter > 0) {
        this.arrowCounter -= 1;
        this.fixScrolling();
      }
    },

    matProps(mat) {
      this.$router.push({
        path: '/products-in-stock',
        query: {
          articleNumber: mat.articleNumber,
          inStock: 'InStock',
        },
      })
    },

    inputChanged(event) {
      if (event.code == 'ArrowUp' || event.code == 'ArrowDown') {
        return;
      }
      // this.getFilteredDresses = [];

      // eslint-disable-next-line no-useless-return
      if (event.code == 'Enter') {
        this.enterPressed = true;
        this.searchByOn(this.getFilteredDresses[this.arrowCounter]);
        this.removeLists();
        this.arrowCounter = 0;
        setTimeout(() => {
          this.enterPressed = false;
        }, 1000);
        // eslint-disable-next-line no-useless-return
        return;
      }
    },
    fixScrolling() {
      const liH = this.$refs.options[this.arrowCounter].clientHeight;
      console.log(liH);
      this.$refs.scrollContainer.scrollTop = liH * this.arrowCounter;
    },
    convertUTCDateToLoacalDate(date) {
      const local = moment.utc(date).local().format('DD-MMM-YYYY h:mm A');
      return local;
    },
    inputChanged2(event) {
      if (event.code == 'ArrowUp' || event.code == 'ArrowDown') { return; }

      // eslint-disable-next-line no-useless-return
      if (event.code == 'Enter') {
        this.enterPressed = true
        this.searchByOn2(this.getFilteredDressColors[this.arrowCounter2])
        this.removeLists2()
        this.arrowCounter2 = 0
        setTimeout(() => {
          this.enterPressed = false
        }, 1000)
        // eslint-disable-next-line no-useless-return
        return;
      }
    },
    fixScrolling2() {
      const liH = this.$refs.options[this.arrowCounter2].clientHeight;
      console.log(liH)
      this.$refs.scrollContainer2.scrollTop = liH * this.arrowCounter2;
    },
    onArrowDown2(ev) {
      ev.preventDefault()
      if (this.arrowCounter2 < this.getFilteredDressColors.length - 1) {
        this.arrowCounter2 += 1;
        this.fixScrolling2();
      }
    },

    onArrowUp2(ev) {
      ev.preventDefault()
      if (this.arrowCounter2 > 0) {
        this.arrowCounter2 -= 1;
        this.fixScrolling2()
      }
    },
    async loadSizes(id) {
      if (this.colorActive === id) {
        // Condition to skip request
        return;
      }

      await this.inventoryProductSizeFromColorId(id);
      this.colorActive = id;
      this.resetProductsByArticleNumber();
    },
    async loadColors(id) {
      // this.changeLoadingtoTrue(false)

      if (this.actualId === id) {
        // Condition to skip request
        this.actualId = '';
      } else {
        await this.loadDressColorsById({ mainDressCategoryId: id, pageNumber: 1, pageSize: this.pageSize });
        this.resetSizes();
        this.resetProductsByArticleNumber();
        this.actualId = id;
      }

      // this.changeLoadingtoTrue(true)
    },
    async loadMaterialByArticleNum(articleNum) {
      await this.productsByArticleNumber(articleNum);
      this.sizeActive = articleNum;
    },
    async searchByOn(value) {
      this.searchByName = value;
      this.noSearch = false;
      await this.loadProductReportingPagination({
        pageNumber: this.page,
        pageSize: this.pageSize,
        mdcName: this.searchByName,
      });
    },
    removeLists() {
      this.noSearch = false;
      this.resetFilteredDresses();
    },
    async searchByOn2(value) {
      this.searchColor = value;
      this.noSearch2 = false;
      this.reporting_inventoryProductSizesByMDCColorName({
        name: this.searchColor,
        pageNumber: this.page,
        pageSize: this.pageSize,
      })
      this.noSearch2 = false
    },
    removeLists2() {
      this.noSearch2 = false;
      this.resetFilteredDressColors();
    },
  },
};
</script>

<style scoped lang="scss">


.hr {
  background-color: grey;
  min-width: 201px;
  height: 1px;
  // transform: matrix(0, 1, -1, 0, 0, 0);
  /* UI Properties */
  border: 0.5px solid #a5a5a5;
  opacity: 0.34;
}

.order-card__title {
  font-weight: 500;
  display: block;
  padding: 0px;
  text-align: left;
  margin: 1px;
  color: #344054;
  font-size: 16px;
}

.createdInput {
  border: 1px solid #D0D5DD;
  display: flex;
  min-height: 40px;
  width: 100%;
  border-radius: 6px;
  color: #667085;
  font-size: 16px;
  font-weight: 400;
  padding: 10px;
  align-items: center;
}

.tab-title-class {
  background-color: #ff0000 !important;
}

.order-card__wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
  column-gap: 20px;
}

.order-card__inside {
  border: 1px solid #f9f9f9;
  box-shadow: none;
  border-radius: 5px;
  margin-top: 15px;
  padding: 5px;
  margin-bottom: 15px;
  position: relative;
}

.pm-li {
  list-style: none;
  background: #ffffff;
  overflow-y: auto;
  border-radius: 8px;
  border: 1px solid transparent;
  box-shadow: none;
  padding: 12px 10px;
  margin-top: 20px;
}

.pm-li h5 {
  margin-bottom: 0;
}

.ul-order {
  padding: 0;
  display: grid;
  grid-template-columns: 1fr;
  gap: 15px;
}

.ul-order li {
  background: #ffffff;
  overflow-y: auto;
  border-radius: 8px;
  border: 1px solid #DADBDC;
  padding: 6px 2px 6px 10px;
  margin-top: 10px;
}

.ul-order ul {
  padding-left: 5px;
}

.order-card {
  display: flex;
  align-items: center;
}

.order-card p {
  margin-right: 20px;
}

.stage-card {
  background: #ffffff;
  border-radius: 8px;
  // border: 1px solid transparent;
  box-shadow: none;
  padding: 10px;
  margin-bottom: 15px;

  margin-right: 10px;
}

.color-card {
  margin-right: 10px;
  cursor: pointer;
  padding: 8px;
  // border: 1px solid #c3c3c3;
  border-radius: 5px;
}

.color-card.active {
  // background-color: #dbdbdb;
}

.button {
  width: 195px;
}

.dropdrop::-webkit-scrollbar {
  display: none;
}

.dropdrop {
  display: inline-block;
  overflow: auto;
  position: absolute;
  background: white;
  width: 227px;
  box-shadow: none;;
}

input[name="search-box"] {
  display: block;
  max-width: 500px;
  width: 95%;
  padding: 0.5rem 1rem;
  padding-left: 25px;
}
.search-item {
  padding: 5px;
  max-width: 500px;
  width: 100%;
  font-weight: 400;
  color: #82868c;
  font-size: 1rem;
  border-bottom: 1px solid #e8e8e8;
}

.search-item:last-child {
  border-bottom: none;
}

.search-item:hover {
  background: #82868c;
  color: white;
}

.search-item hr {
  color: lightgray;
  border-top: none;
  margin: -1.3rem 0 0.5rem 0;
}

.active2 {
  font-weight: bold;
  color: $base-color;
}

.is-active {
  background-color: #dedede;
}
</style>
